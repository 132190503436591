.MySearchComponent input[type="text"] {
  border: none;
  background-color: #e8f0fe;
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 50px;
  max-width: 400px;
}

.MySearchComponent input[type="text"]::placeholder {
  color: #a2a2a2;
}

.MySearchComponent button {
  background-color: #e8f0fe;
  border: none;
  color: #a2a2a2;
  border-radius: 50px;
  font-size: 16px;
  padding: 12px 16px;
  margin-left: -50px;
  cursor: pointer;
}

.MySearchComponent button:hover {
  background-color: #d5e1fa;
}
