:root {
  --light-card-color: rgb(247, 249, 249);
}

.side-content-card {
  margin-bottom: 1rem;
  padding: 1rem;
  border: solid 1px var(--light-card-color);
  border-radius: 5px;
  background-color: var(--light-card-color);
}

.side-content-card h1 {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.side-feed,
.side-profile-feed {
  display: flex;
  padding: 0.5rem 0;
}

.side-feed p {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  padding: 0 7px;
}

.side-feed-header {
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}

.side-feed-content {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
}

.header-padding {
  padding: 0 7px;
}
