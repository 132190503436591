textarea {
    border-radius: 0.375rem;
    border: 0px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 2%;
}

textarea:focus {
    outline: 0;
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}
