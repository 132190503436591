:root {
  --dark-theme-color: #000000; /* black */
  --light-theme-color: #ffffff; /* white */
  --primary-color: #1da1f2; /* blockso blue color */
  --darker-primary-color: #0b98f0cc; /* a darker shade of #1DA1F2 */

  /* padding and spacing */
  --page-top-padding: 2rem;
  --page-bottom-padding: 2rem;
}

a {
  text-decoration: none;
  color: #0076ff;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 499px) {
  .app-container {
  }
}

/* home page grid */
.content-container {
}
/* main content column */
.main-content {
  width: 100%;
  height: auto;
  overflow: auto;
}

.side-content {
  max-width: 350px;
  max-height: 100vh;
  overflow: auto;
  position: sticky;
  top: 0;
  bottom: 0;
}

@media (max-width: 987px) {
  .main-content {
    width: calc(100% - 363px);
  }
  .side-content {
    display: none;
  }
}

.fullheight-container {
  min-height: 100vh;
}

.outline-red {
  outline: 2px solid red;
}

.outline-green {
  outline: 2px solid green;
}

.outline-blue {
  outline: 2px solid blue;
}

.pointer:hover {
  cursor: pointer;
}

.tab-min-height {
  min-height: 20vh;
}

.link-style {
  color: black;
}

.link-style:hover {
  color: #4285f4;
  text-decoration: underline;
}

.link-active {
  text-decoration: underline;
  text-decoration-color: #00a8e8;
  text-decoration-thickness: 1%;
}

.liked-by-me {
  border-width: 1px !important;
  border-color: #dc3545 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #dc3545 !important;
}

.liked-by-me:active:hover {
  box-shadow: 0 0 0 0.1rem rgba(220, 53, 69, 0.5) !important;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.liked-by-me:active:hover::after {
  opacity: 1;
}

.not-liked-by-me {
  border-width: 1px;
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
}

.not-liked-by-me:active:hover {
  box-shadow: 0 0 0 0.1rem rgba(220, 53, 69, 0.5) !important;
  color: #dc3545 !important;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.not-liked-by-me:active:hover::after {
  opacity: 1;
}

/* EXPLORE PAGE */

.info-container {
  background-color: #003459;
  color: #fff;
}

.info-container h1 {
  font-family: Luckiest Guy, sans-serif;
  font-size: 4.5rem;
  word-break: break-word;
  word-wrap: break-word;
}

.info-container p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 24px;
  text-transform: none;
}

.card-body:hover {
  background-color: #f8f9fa;
}

.btn-primary.custom-btn {
  background-color: #00a8e8;
}

.btn-primary.custom-btn:hover {
  background-color: #007ea7;
}

.btn-secondary {
  background-color: white;
  color: #00a8e8;
  border-color: #00a8e8;
}

.btn-secondary:hover {
  background-color: #00a8e8;
  color: white;
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

/* Form PFP */
.delete-icon {
  font-size: 15px;
}

.delete-icon:hover {
  color: red;
}

.w-min-100 {
  min-width: 100vw;
}

.h-min-100 {
  min-height: 100vh;
}

/* luckiest-guy-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Luckiest Guy";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/luckiest-guy/luckiest-guy-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/luckiest-guy/luckiest-guy-v18-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/luckiest-guy/luckiest-guy-v18-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/luckiest-guy/luckiest-guy-v18-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/luckiest-guy/luckiest-guy-v18-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/luckiest-guy/luckiest-guy-v18-latin-regular.svg#LuckiestGuy")
      format("svg"); /* Legacy iOS */
}
