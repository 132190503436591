/* FOLLOWER/FOLLOWING PAGE */

.light-hover:hover {
  background-color: whitesmoke;
}

.primary-color-hover:hover {
  color: #4285f4;
}

.outline-primary {
  color: #4285f4;
  background-color: white;
  border-color: #4285f4;
}

.follow-page {
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
}

.following-container {
  padding: 0;
  /*  overflow: hidden; */
}

.follow-card {
  width: 100%;
  transition: transform 0.2s ease-in-out;
  padding: 0.5rem 0;
}

.follow-card:hover {
  transform: scale(1.02);
}

.follow-body {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
}

.follow-body button {
  width: 100px;
  text-align: center;
}

.follow-header {
  padding: 16px 8px;
}

.arrow:hover {
  background-color: whitesmoke;
  border-radius: 2rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--primary-color);
  color: white;
}

.nav-pills .nav-link {
  background-color: white;
  color: black;
}

@media (max-width: 575px) {
  .follow-card {
    padding: 1rem 0rem;
  }
  .follow-body {
    align-items: center;
    justify-content: space-around;
  }
  .follow-btn {
    padding-top: 1rem;
  }
  .bio {
    text-align: center;
  }
}
