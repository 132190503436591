.main-content-header {
  height: 55px;
}

.header-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 12px;
}

.header-div h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

/* Hides the sign in button on the main header when the screen width is less than 1264px. */
@media (min-width: 1264px) {
  .main-content-header .header-div .sign-in-button {
    display: none;
  }
}

@media (max-width: 499px) {
  .main-content-header .header-div .sign-in-button {
    display: none;
  }
}
