#feed-options-dropdown::after {
    display: none;
}

.dropdown-menu {
    overflow: hidden!important;
}

.dropdown-item:active {
    background-color: #e9ecef!important;
}

